.create-team-container {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.create-team-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.create-team-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    color: aliceblue;
}

.create-team-members-input {
    height: 100px;
}

.shrek {
    max-width: 100%;
}

label {
    color: aliceblue;
}
