.asso {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.asso-logo {
    max-width: 100%;
    max-width: 250px;
}

.asso-texte {
    max-width: 100%;
    width: 800px;
    text-align: justify;
    padding-top: 20px;
    color: aliceblue;
}

.title {
    color: aliceblue;
    text-align: left;
    padding-left: 5%;
}

.wrap {
    width: 1400px;
    max-width: 100%;
    padding-left: 20px;
    padding-top: 70px;
    gap: 50px;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
}

.impaire {
    flex-direction: row-reverse;
}
