.circle {
    font-size: 40px;
}

@keyframes blink {
    from {
        opacity: 0%;
    }

    to {
        opacity: 100%;
    }
}

.type-writer::after {
    content: "|";
    animation: blink 0.5s infinite alternate-reverse;
}

.type-writer {
    align-self: center;
    min-width: 300px;
    width: 100%;
    border-width: 10px;
    background-color: rgba(8, 8, 26, 0.473);
    border-color: rgb(41, 41, 41);
    height: 300px;
    word-wrap: break-word;
    color: white;
    border-style: solid;
    padding: 6px;
    font-family: 'Ubuntu';
    overflow: visible;
    font: 'Helvetica Neue';
    margin-top: 1em;
}

.accueil-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 5rem;
    padding-right: 5rem;
}

.advancement-bar {
    align-self: center;
    text-align: center;
}

.no-team {
    align-self: center;
    justify-self: center;
    color: white;
    font-size: 1.5rem;
}

#team-name {
    font-size: 1.5em;
    color: white;
    margin-top: 0.5em;
    margin-bottom: 0.2em;
}

#round-name {
    font-size: 1.5em;
    font-weight: bold;
    color: white;
}
