.leaderboard-head {
    display: flex;
    justify-content: space-around;
}

.leaderboard-head-text {
    font-weight: bold;
    text-align: center;
    color: aliceblue;
}

.leaderboard-body-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: aliceblue;
}

.leaderboard-body {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: aliceblue;
}

.team-line {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: black;
    border-radius: 13px;
    border-color: aliceblue;
}

.team-line-element {
    width: 10%;
    text-align: start;
    min-width: 100px;
}
