.create-admin-container {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.create-admin-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: aliceblue;
}

.create-admin-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  color: aliceblue;
}

.create-admin-superadmin {
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-admin-superadmin-input {
  width: 20px;
  height: 20px;
  margin: 0 10px;
}

.activities-container {
  width: 95%;
  margin-top: 30px;
}

.activities-title {
  font-size: 36px;
  margin-bottom: 20px;
}

.activity {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px 0;
}

.activity-text {
  margin: 5px 0;
}

.activity-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.add-activity-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.button {
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
}

.button.add {
  width: 220px;
  background: #40c057;
}

.button.delete {
  width: 150px;
  background: #e62222;
}

.button.send {
  width: 150px;
  background: #40c057;
}

.button,
.button span {
  transition: 200ms;
}

.button .text {
  transform: translateX(20px);
  color: white;
  font-weight: bold;
}

.button .icon {
  position: absolute;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button .icon.delete {
  border-left: 1px solid #c41b1b;
  transform: translateX(105px);
}

.button .icon.add {
  border-left: 1px solid #009000;
  transform: translateX(175px);
}

.button .icon.send {
  border-left: 1px solid #009000;
  transform: translateX(105px);
}

.button:hover .text {
  color: transparent;
}

.button:hover .icon {
  border-left: none;
  transform: translateX(0);
}

.button.delete svg {
  width: 15px;
  fill: #eee;
}

.button.add svg {
  width: 30px;
}

.button.send svg {
  width: 25px;
}

.button:focus {
  outline: none;
}
.button:active .icon svg {
  transform: scale(0.8);
}

.button.delete:hover {
  background: #ff3636;
}

.button.add:hover {
  background: #40c057;
}

.button.send:hover {
  background: #40c057;
}

.button:hover .icon.delete {
  width: 150px;
}

.button:hover .icon.add {
  width: 220px;
}

.button:hover .icon.send {
  width: 150px;
}

.asso-input {
  margin: 10px 0;
}

.asso-input input {
  margin-left: 10px;
}

.description-input {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}

.description-input textarea {
  margin-left: 10px;
  width: 80%;
  max-width: 100%;
  min-height: 100px;
  max-height: 200px;
  resize: vertical;
}

.admin {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 20px;
  padding: 10px 0;
}

.admin p {
  width: 30%;
}

.admin p.center {
  text-align: center;
}

.size-16 {
  font-size: 16px;
}

.size-12 {
  font-size: 12px;
}

.margin-admin {
  margin-bottom: 80px;
  margin-top: 20px;
}

.border-activity {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

@media (max-width: 600px) {
  .admin {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    font-size: 20px;
    padding: 10px 0;
  }

  .admin p {
    width: 80%;
    text-align: center;
  }
}
